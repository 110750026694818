import Glide from '@glidejs/glide';

function initProductCarousels() {
  return (() => {
    if (document.querySelector('.glide--reccommended')) {
      document.querySelector('.glide--reccommended').classList.remove('display-none');
      new Glide('.glide--reccommended', {
        type: 'carousel',
        focusAt: 0,
        perTouch: 1,
        perView: 4,
        touchRatio: 1,
        startAt: 0,
        rewind: false,
      }).mount();
    }
  })();
}

export default {
  initProductCarousels
};
