export const REQUEST_CART_ITEMS = 'REQUEST_CART_ITEMS';
export const RECEIVE_CART_ITEMS = 'RECEIVE_CART_ITEMS';
export const RECEIVE_CART_ITEMS_SUCCESSFULLY = 'RECEIVE_CART_ITEMS_SUCCESSFULLY';
export const RESET_CART_VALIDATION = 'RESET_CART_VALIDATION';

export const INCREASE_CART_ITEM = 'INCREASE_CART_ITEM';
export const DECREASE_CART_ITEM = 'DECREASE_CART_ITEM';

export const CART_ITEM_ALERT_CLASS = 'cart-item__alert';
export const CART_ITEM_NO_STOCK_ALERT_CLASS = 'cart-item__alert--no-stock';

export const SCROLL_TO_TOP_DURATION = 200;

export const GET_CART_URL = '/get';
export const UPDATE_CART_ITEM_URL = '/updateCartEntry';
export const REMOVE_CART_ITEM_URL = '/removeCartEntry';
export const VALIDATE_CART_URL = '/validate';

export const ERROR_LIST = ['lowStock', 'noStock'];
export const RESET_ERRORS_FLAG = 'RESET_ERRORS_FLAG';

export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL';
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const SAVE_INFO_DELETE_ITEM = 'SAVE_INFO_DELETE_ITEM';
export const DELETE_INFO_DELETE_ITEM = 'DELETE_INFO_DELETE_ITEM';