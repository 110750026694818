import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import { ajaxRequest } from 'app/utils/helpers';

// TODO: instead of hardcoding locale-data here it has to be moved to project-specific folder
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';

const { messagesUrl, locale } = window.inlineGlobalConfig;


addLocaleData([...en, ...es]);

let translationPromise = null;
let hasTranslationsBeenRequested = false;
let globalTranslations = null;

const ProviderHOC = (store = null) =>
  (WrappedComponent) =>
    class ProviderWrapper extends Component {
      constructor(props) {
        super(props);
        this.state = {
          translations: null
        };
        this.setMessageProperties = this.setMessageProperties.bind(this);
      }

      componentWillMount() {
        this.setMessageProperties();
      }

      setMessageProperties() {
        if (hasTranslationsBeenRequested) {
          translationPromise.then(response => {
            this.setState({
              translations: response
            });
            return response;
          });
        } else {
          translationPromise = ajaxRequest('GET', messagesUrl).then((response) => {
            globalTranslations = response;
            this.setState({
              translations: response
            });
            return response;
          });
          hasTranslationsBeenRequested = true;
        }
      }

      render() {
        const { translations } = this.state;

        if (!translations) {
          return null;
        }

        if (!store) {
          return (
            <IntlProvider locale={locale.language} messages={translations}>
              <WrappedComponent {...this.props} />
            </IntlProvider>
          );
        }

        return (
          <IntlProvider locale={locale.language} messages={translations}>
            <Provider store={store}>
              <WrappedComponent {...this.props} />
            </Provider>
          </IntlProvider>
        );
      }
    };

export const getTranslations = () => Object.assign({}, globalTranslations);
export default ProviderHOC;
