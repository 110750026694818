export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const REQUEST_ADD_PRODUCTS = 'REQUEST_ADD_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_ADD_PRODUCTS = 'RECEIVE_ADD_PRODUCTS';
export const CLICK_PRODUCT_ITEM = 'CLICK_PRODUCT_ITEM';
export const PRDUCT_ITEM_MOUNT = 'PRDUCT_ITEM_MOUNT';
export const CLICKED_PRODUCT_SCROLL_POSITION = 'CLICKED_PRODUCT_SCROLL_POSITION';

export const INITIAL_PRODUCTS_DATA = {
  products: [],
  searchMetadata: { facets: [], sortFields: [], totalItems: 0 },
  page: 0
};

export const LOADER_TIMEOUT = 1000;
export const BACK_TO_TOP_DURATION = 200;
export const BACK_TO_PRODUCT_DURATION = 200;

export const LOAD_1_PRODUCT_PER_ROW = 1;
export const LOAD_2_PRODUCTS_PER_ROW = 2;
export const LOAD_3_PRODUCTS_PER_ROW = 3;
export const LOAD_4_PRODUCTS_PER_ROW = 4;

export const TIME_BEFORE_INTERSECT = 200;

export const GRID_TEMPLATES = {
  R1xC1: {
    1: {
      template: 'template_for_1_CS',
      availableSlots: 0,
      complete: true
    },
    2: {
      template: 'template_2_cols_R1xC1',
      availableSlots: 1
    },
    3: {
      template: 'template_3_cols_R1xC1',
      availableSlots: 2
    },
    4: {
      template: 'template_4_cols_R1xC1',
      availableSlots: 3
    }
  },
  R1xC2: {
    1: {
      template: 'template_for_1_CS',
      availableSlots: 0,
      complete: true
    },
    2: {
      template: 'template_for_1_CS',
      availableSlots: 0,
      complete: true
    },
    3: {
      template: 'template_3_cols_R1xC2',
      availableSlots: 1
    },
    4: {
      template: 'template_4_cols_R1xC2',
      availableSlots: 2
    },
  },
  R2xC1: {
    1: {
      template: 'template_for_1_CS',
      availableSlots: 0,
      complete: true
    },
    2: {
      template: 'template_2_cols_R2xC1',
      availableSlots: 2
    },
    3: {
      template: 'template_3_cols_R2xC1',
      availableSlots: 4
    },
    4: {
      template: 'template_4_cols_R2xC1',
      availableSlots: 6
    }
  },
  R2xC2: {
    1: {
      template: 'template_for_1_CS',
      availableSlots: 0,
      complete: true
    },
    2: {
      template: 'template_for_1_CS',
      availableSlots: 0,
      complete: true
    },
    3: {
      template: 'template_3_cols_R2xC2',
      availableSlots: 2
    },
    4: {
      template: 'template_4_cols_R2xC2',
      availableSlots: 4
    }
  },
  standard: {
    1: {
      template: 'template_for_1',
      availableSlots: 1,
      contentSpot: null,
      products: [],
      complete: false,
      order: 'asc'
    },
    2: {
      template: 'template_for_2',
      availableSlots: 2,
      contentSpot: null,
      products: [],
      complete: false,
      order: 'asc'
    },
    3: {
      template: 'template_for_3',
      availableSlots: 3,
      contentSpot: null,
      products: [],
      complete: false,
      order: 'asc'
    },
    4: {
      template: 'template_for_4',
      availableSlots: 4,
      contentSpot: null,
      products: [],
      complete: false,
      order: 'asc'
    }
  }
};

export const GRID_ORDER = {
  LEFT: 'asc',
  RIGHT: 'desc'
};
