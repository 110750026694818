import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormGroup, FormError } from 'react-forms';
import Button from '../atoms/button';

const SubscriptionCoupon = ({
  formName,
  handleValidForm,
  handleInvalidForm,
  validation,
  onSubmit,
  rootBEM,
  rootMessages,
  buttonText
}, context) => (
  <form name={formName} className={`${rootBEM}__form`}>
    <FormGroup
      formName={formName}
      handleValidForm={handleValidForm}
      handleInvalidForm={handleInvalidForm}
      validation={validation}
    >
      <div className={`${rootBEM}__item-email`}>
        <input
          type="email"
          name={`${rootBEM}-email`}
          id={`${rootBEM}-email`}
          value=""
          required
          aria-label={context.intl.formatMessage({ id: `${rootMessages}.email.placeholder` })}
          placeholder={context.intl.formatMessage({ id: `${rootMessages}.email.placeholder` })}
          className={`${rootBEM}__item-email-input`}
        />
      </div>

      <div className={`${rootBEM}__item-button`}>
        <Button
          buttonOnClick={onSubmit}
          buttonText={buttonText}
          buttonClassName="expand no-margin-top no-margin-bottom"
        />
      </div>

      <div className={`${rootBEM}__item-error`}>
        <FormError forInput={`${rootBEM}-email`} />
        <span
          className={`${rootBEM}__item-gender-error form-msg-error form-msg-error--email`}
          style={{ display: 'none' }}
        >
          <FormattedMessage id={`${rootMessages}.gender.error`} />
        </span>
      </div>
    </FormGroup>
  </form>
);

SubscriptionCoupon.contextTypes = {
  intl: PropTypes.object.isRequired,
};

SubscriptionCoupon.propTypes = {
  formName: PropTypes.string.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rootBEM: PropTypes.string.isRequired,
  rootMessages: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default SubscriptionCoupon;
