export const ACCOUNT_INFO_URL = '/info';
export const ORDER_HISTORY_URL = '/order-history';
export const ADDRESS_BOOK_URL = '/address-book';
export const ADDRESS_BOOK_LIST_URL = '/address-book/list';
export const ADDRESS_BOOK_ADD_URL = '/address-book/add';
export const ADDRESS_BOOK_EDIT_URL = '/address-book/:id';
export const PAYMENT_INFO_URL = '/payment-info';
export const SUBSCRIPTIONS_URL = '/subscriptions';
export const ORDER_DETAILS_URL = '/order-details';
export const ORDERS_LIST_URL = '/orders-list';
export const MY_PROFILE_URL = '/my-profile';

export const ACCOUNT_INFO_MOUNT = 'ACCOUNT_INFO_MOUNT';
export const ORDER_HISTORY_MOUNT = 'ORDER_HISTORY_MOUNT';
export const ORDER_DETAILS_MOUNT = 'ORDER_DETAILS_MOUNT';
export const ADDRESS_BOOK_MOUNT = 'ADDRESS_BOOK_MOUNT';
export const ADDRESS_BOOK_EDIT_MOUNT = 'ADDRESS_BOOK_EDIT_MOUNT';
export const PAYMENT_INFO_MOUNT = 'PAYMENT_INFO_MOUNT';
export const USER_IS_REGISTERED_SUCCESSFULLY = 'USER_IS_REGISTERED_SUCCESSFULLY';
export const MY_PROFILE_MOUNT = 'MY_PROFILE_MOUNT';

export const ACCOUNT_APP = 'accountApp';
