import React from 'react';
import ProductItem from './productItem';

const ProductItemWrapper = (props) => {
  const {
    product,
    clickProductItem,
    isGridView,
    productItemMounted,
    updateImageReferences,
    enableInfiniteScroll,
    setClickBadgeItem
  } = props;

  return (
    <ProductItem
      product={product}
      resultPosition={product.resultPosition}
      onClick={clickProductItem}
      hasMoreProductsPerRow={isGridView}
      productItemMounted={productItemMounted}
      updateImageReferences={updateImageReferences}
      enableInfiniteScroll={enableInfiniteScroll}
      enableAddToBag={true}
      setClickBadgeItem={setClickBadgeItem}
    />
  );
};

export default ProductItemWrapper;
