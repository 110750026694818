import React from 'react';
import PropTypes from 'prop-types';
import ThumborUtils from './thumborUtils';

const {
  webpQuality, mediaQueries, mediaProvider, thumborConfig
} = window.inlineGlobalConfig;

const getWebpQuality = (width) => {
  let prevValue = 0;
  let quality = Object.values(webpQuality).slice(-1)[0];

  Object.entries(mediaQueries).forEach(([key, value]) => {
    if (prevValue < width && width <= value) {
      quality = webpQuality[key];
    }
    prevValue = value;
  });

  return quality;
};

const renderImageUrl = (url, width, media, webp) => {
  if (!url) {
    return '/assets/no_product_image.png';
  }

  const provider = media.mediaProvider ? media.mediaProvider : mediaProvider;
  const webpExtension = webp ? '.webp' : '';
  const webpQualityPostfix = webp ? `&qlt=${getWebpQuality(width)}` : '';
  switch (provider) {
    case 'AMPLIENCE':
    case 'AMPLIENCE_IMAGE':
      return `${url}${webpExtension}?w=${width}${webpQualityPostfix}`;
    default: {
      const thumborUtils = new ThumborUtils(thumborConfig);
      return thumborUtils
        .setImagePath(encodeURIComponent(url))
        .fitIn(width)
        .buildUrl();
    }
  }
};

const renderImageUrlWithWidth = (url, size, media, retinaMultiplier = 1, webp) => {
  const width = parseInt((size * retinaMultiplier), 10);
  return `${renderImageUrl(url, width, media, webp)} ${width}w`;
};

const getSrcsetURLs = (srcset, url, media, webp = false) => {
  const retinaMultiplierX2 = 2;
  const retinaMultiplierX3 = 3;
  const nonRetina = srcset.map((size) => renderImageUrlWithWidth(url, size, media, 1, webp));
  const x2 = srcset.map((size) => renderImageUrlWithWidth(url, size, media, retinaMultiplierX2, webp));
  const x3 = srcset.map((size) => renderImageUrlWithWidth(url, size, media, retinaMultiplierX3, webp));

  return [...new Set([
    ...nonRetina,
    ...x2,
    ...x3
  ])];
};

const getSrcsets = (sizes) => {
  let prevSize = '100vw';
  const sizesAvailable = Object.keys(mediaQueries).map((key) => {
    const currentSize = sizes[key] ? sizes[key] : prevSize;
    prevSize = currentSize;
    return {
      [key]: currentSize
    };
  });

  const srcSets = sizesAvailable.map((sizeObj) => {
    const LAST_TWO_CHARACTERS = -2;
    const hundredPercent = 100;
    const sizeKey = Object.keys(sizeObj)[0];
    const sizeValue = sizeObj[sizeKey];
    const unit = sizeValue.slice(LAST_TWO_CHARACTERS);
    const mediaQuery = parseInt(sizeValue.replace(unit, ''), 10);
    return unit === 'px' ? mediaQuery : Math.round((mediaQueries[sizeKey] * mediaQuery) / hundredPercent);
  });

  return [...new Set(srcSets)];
};

const mediaQueriesMinWidth = () => {
  let prevKey = '';
  return Object.keys(mediaQueries).map((key) => {
    const currentMinWidth = mediaQueries[prevKey] ? (mediaQueries[prevKey] + 1) : 0;
    prevKey = key;
    return {
      [key]: currentMinWidth
    };
  }).reduce((prev, curr) => Object.assign(prev, curr), {});
};

const getSizes = (sizes) =>
  Object.keys(sizes).map((sizeKey) =>
    `(min-width:${mediaQueriesMinWidth()[sizeKey]}px) ${sizes[sizeKey]}`
  ).reverse();

const Image = ({
  media, sizes, lazyLoad, updateImageReferences, cName, ...otherProps
}) => {
  const srcSets = getSrcsets(sizes);
  if (lazyLoad) {
    // const RENDER_IMAGE_URL = renderImageUrl(media.infiniteUrl, Math.min(...srcSets), media);
    // const SRC = RENDER_IMAGE_URL === 'none' ? RENDER_IMAGE_URL : false;

    return (
      <picture ref={updateImageReferences}>
        <source
          data-sizes={getSizes(sizes).join(',')}
          data-srcset={getSrcsetURLs(srcSets, media.url, media, true).join(',')}
          srcSet={media.infiniteUrl ? getSrcsetURLs(srcSets, media.infiniteUrl, media, true).join(',') : ''}
          type="image/webp"
        />
        <img
          alt=""
          className="lazyload"
          data-src={renderImageUrl(media.url, Math.min(...srcSets), media)}
          data-sizes={getSizes(sizes).join(',')}
          data-srcset={getSrcsetURLs(srcSets, media.url, media).join(',')}
          srcSet={media.infiniteUrl ? getSrcsetURLs(srcSets, media.infiniteUrl, media).join(',') : ''}
          {...otherProps}
        />
      </picture>
    );
  }

  return (
    <picture>
      <source
        sizes={getSizes(sizes).join(',')}
        srcSet={getSrcsetURLs(srcSets, media.url, media, true).join(',')}
        type="image/webp"
      />
      <img
        alt=""
        className={`lazyload ${cName}`}
        data-src={renderImageUrl(media.url, Math.min(...srcSets), media)}
        sizes={getSizes(sizes).join(',')}
        srcSet={getSrcsetURLs(srcSets, media.url, media).join(',')}
        {...otherProps}
      />
    </picture>
  );
};

Image.propTypes = {
  media: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  lazyLoad: PropTypes.bool,
  updateImageReferences: PropTypes.func,
  cName: PropTypes.string
};

export default Image;
