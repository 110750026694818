import * as CONST from './globalMessagesConstants';

export const setServerMsg = (message, msgType, shouldAutoHide = false, isLocalisableMessageId = false, isStickyMessage = false, values) => ({
  type: CONST.SET_SERVER_MSG,
  message,
  msgType,
  shouldAutoHide,
  isLocalisableMessageId,
  isStickyMessage,
  values
});

export const setServerMsgID = (id, type, isAutoHide = false, isStickyMessage = false) => setServerMsg(id, type, isAutoHide, true, isStickyMessage);

export const setStickyMsgID = (id, type, isAutoHide = false) => setServerMsg(id, type, isAutoHide, true, true);

export const setStickyMsg = (message, type, isAutoHide = false) => setServerMsg(message, type, isAutoHide, false, true);

export const setStickyMsgIDWithValues = (id, type, isAutoHide = false, values = 0) => setServerMsg(id, type, isAutoHide, true, true, values);

export const closeGlobalMessage = () => ({
  type: CONST.CLOSE_MESSAGE
});
