import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  buttonClassName,
  buttonOnClick,
  buttonText
}) => (
  <button
    type="submit"
    className={`button ${buttonClassName || ''}`}
    onClick={buttonOnClick}
  >
    <span>{buttonText}</span>
  </button>
);

Button.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  buttonOnClick: PropTypes.func
};

export default Button;
