import React from 'react';
import PropTypes from 'prop-types';

const productMetaPrice = ({price}) => (
  <div className="product-meta__price">{price}</div>
);

productMetaPrice.propTypes = {
 price: PropTypes.any
};

export default productMetaPrice;
