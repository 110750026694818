import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setServerMsg } from './globalMessagesActions';
import { GLOBAL_MESSAGES_ELEMENT_ID, MAX_DISPLAY_TIME } from './globalMessagesConstants';
import { FormattedHTMLMessage } from 'react-intl';
import { getElementHeightNoPadding } from 'app/utils/helpers';

class GlobalMessagesApp extends Component {
  static handleAddOpenClass() {
    const globalMessagesElement = document.getElementById(GLOBAL_MESSAGES_ELEMENT_ID);
    if (globalMessagesElement && globalMessagesElement.className === '') {
      globalMessagesElement.className += 'notification--open';
    }
  }

  constructor() {
    super();

    this.state = {
      stickyClass: '',
      isOpen: false,
      isFadeOutInProcess: false
    };
    this.prevScrollTop = 0;
    this.headerHeight = 0;
    this.globalMessageDIV = null;
    this.handleScrollEvent = this.handleScrollEvent.bind(this);
    this.handleTransition = this.handleTransition.bind(this);
    this.autoHidingTimer = null;
  }

  componentDidMount() {
    const { headerSelector } = this.props;
    this.globalMessageDIV.addEventListener('transitionend', this.handleTransition);
    if (headerSelector) {
      const headerElements = document.querySelectorAll(headerSelector);
      [].forEach.call(headerElements, (headerElement) => {
        this.headerHeight += getElementHeightNoPadding(headerElement);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      isStickyMessage, isSticky, message, shouldBeClosed
    } = nextProps;
    if (message && shouldBeClosed) {
      this.closeAlertBox();
    }
    if (isStickyMessage || isSticky) {
      if (message) {
        this.handleAutoHiding(nextProps);
        document.addEventListener('scroll', this.handleScrollEvent);
      } else {
        document.removeEventListener('scroll', this.handleScrollEvent);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { message, isStickyMessage, isSticky } = this.props;
    if (message && !prevProps.message) {
      this.openGlobalMessage();
      if (isStickyMessage || isSticky) {
        this.handleScrollEvent();
      }
    }
  }

  handleTransition() {
    if (!this.state.isOpen) {
      this.setState({
        isFadeOutInProcess: false
      });
      this.props.setServerMsg();
    }
  }

  handleScrollEvent() {
    const { stickyClass } = this.props;
    const scrollTop = window.pageYOffset;
    const { prevScrollTop, headerHeight } = this;
    if (this.state.isOpen) {
      GlobalMessagesApp.handleAddOpenClass();
    }
    if (scrollTop > headerHeight && prevScrollTop <= headerHeight) {
      this.setState({
        stickyClass
      });
    } else if (scrollTop < headerHeight && prevScrollTop >= headerHeight) {
      this.setState({
        stickyClass: ''
      });
    }
    this.prevScrollTop = scrollTop;
  }

  handleAutoHiding(props) {
    const {
      shouldAutoHide,
    } = props;
    if (shouldAutoHide) {
      clearTimeout(this.autoHidingTimer);
      this.autoHidingTimer = window.setTimeout(() => this.closeAlertBox(), MAX_DISPLAY_TIME);
    }
  }

  openGlobalMessage() {
    if (this.state.stickyClass !== '') {
      GlobalMessagesApp.handleAddOpenClass();
    }
    this.setState({
      isOpen: true,
    });
  }

  closeAlertBox() {
    if (document.getElementById(GLOBAL_MESSAGES_ELEMENT_ID)) {
      document.getElementById(GLOBAL_MESSAGES_ELEMENT_ID).className = '';
    }
    this.setState({
      isOpen: false,
      isFadeOutInProcess: true
    });
  }

  render() {
    const {
      message,
      msgType = '',
      isLocalisableMessageId,
      customClass,
      isSticky,
      isStickyMessage,
      values
    } = this.props;
    const { stickyClass, isOpen, isFadeOutInProcess } = this.state;
    const animationClass = isOpen ? 'is-open' : 'is-closing';

    if (!message) {
      return <div ref={(el) => { this.globalMessageDIV = el; }} />;
    }

    return (
      <div
        role="alert"
        className={`alert-box ${msgType} ${customClass}` +
        ` ${isSticky || isStickyMessage ? `is-sticky ${stickyClass}` : ''}` +
        ` ${!isFadeOutInProcess && !isOpen ? 'is-closed' : animationClass}`}
        ref={(el) => { this.globalMessageDIV = el; }}
      >
        {
          isLocalisableMessageId ?
            <FormattedHTMLMessage id={message} values={values} description="Alert by id" /> :
            <div dangerouslySetInnerHTML={{ __html: message }} />
        }
        <button onClick={() => this.closeAlertBox()} className="alert-box__close icon-close" />
      </div>
    );
  }
}

GlobalMessagesApp.defaultProps = {
  isLocalisableMessageId: false,
  customClass: '',
  stickyClass: 'is-sticky--fixed'
};

GlobalMessagesApp.propTypes = {
  message: PropTypes.string,
  msgType: PropTypes.string,
  isLocalisableMessageId: PropTypes.bool,
  setServerMsg: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  isSticky: PropTypes.bool,
  isStickyMessage: PropTypes.bool,
  values: PropTypes.array,
  headerSelector: PropTypes.string,
  stickyClass: PropTypes.string,
  shouldBeClosed: PropTypes.bool
};

const mapStateToProps = ({ GlobalMessages }) => ({
  ...GlobalMessages
});

const mapDispatchToProps = (dispatch) => ({
  setServerMsg: (...args) => dispatch(setServerMsg(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalMessagesApp);
