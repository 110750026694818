import React, { Component } from 'react';
import PropTypes, { array } from 'prop-types';
import ProductPricesComponent from './productPricesComponent';
import Img from 'app/utils/image';
import { isEmpty } from 'lodash';
import AddToBag from "app/commonComponents/addToBag/addToBagContainer";
import HeroBadge from "app/commonComponents/heroBadge/heroBadge";
import { connect } from 'react-redux';
import ProductListComponentHeroBadge from 'app/productListComponent/ProductListComponentHeroBadge';
import { FormattedMessage } from 'react-intl';

/*
  NOTE: please ensure you add your changes to _ProductItem.gsp if you modify this file
*/

class ProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: this.props.product.heroImage,
      imgSrcInfinite: '',
      domReference: [],
      recentlyViewed: false,
      modal: false,
      id: ""
    };
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleProductClick = this.handleProductClick.bind(this);
    this.updateImageReferences = this.updateImageReferences.bind(this);
   
    this.handleOpenCloseModal = this.handleOpenCloseModal.bind(this);
    this.handleCloseAllModal = this.handleCloseAllModal.bind(this);
  }

  componentDidMount() {
    const { productItemMounted } = this.props;
    if (productItemMounted) {
      productItemMounted();
    }
    this.setState({
      modal: false,
      id: ""
    });
  }

  handleOpenCloseModal(id) {
    const { setClickBadgeItem, clickBadgeItem } = this.props;
    const countModal = document.querySelectorAll('.active-hero-badge-modal').length;

    if(clickBadgeItem) { id = (clickBadgeItem.id == id) ? null : id; }

    if (setClickBadgeItem) {
      setClickBadgeItem(id);
    }

    if(countModal < 1) {
      this.setState({
        modal: !this.state.modal,
        id: id
      });
    }
  }

  handleCloseAllModal() {
    this.setState({
      modal: !this.state.modal,
      id: this.state.id
    });
  }

  handleMouseOut() {
    const { altImage, heroImage } = this.props.product;
    const { domReference } = this.state;

    if (altImage) {
      this.setState({
        imgSrc: heroImage,
        imgSrcInfinite: (!isEmpty(domReference) && domReference[0].classList.contains('lazy-loaded')) ? heroImage : ''
      });
    }
  }

  handleProductClick(event) {
    const { product, onClick, resultPosition } = this.props;
    onClick(product, resultPosition, event);
  }

  updateImageReferences(domRef) {
    const { updateImageReferences } = this.props;
    const { domReference } = this.state;
    domReference.push(domRef);
    updateImageReferences(domRef);
  }

  render() {
    const {
      product, enableAddToBag, hasMoreProductsPerRow, enableInfiniteScroll, appendQueryToUrl, cName, clickBadgeItem
    } = this.props;

    const { imgSrc, imgSrcInfinite } = this.state;
    let badgeNameFinder;
    let productDataAndPrices;
    const heroBadges = (product.heroBadges !== null) ? product.heroBadges : [];
    const promoBadges = (product.promoBadges !== null) ? product.promoBadges : [];

    let heroBadge;

    if(product.heroBadges != null)
    {
      heroBadge = product.heroBadges.values != null && product.heroBadges.descriptions && product.heroBadges.values.map((badge, index) =>
      <ProductListComponentHeroBadge
        handleOpenCloseModal={this.handleOpenCloseModal}
        id={`hero-badge-element-${product.resultPosition}`}
        modal={this.state.modal}
        description={product.heroBadges.descriptions[0]}
        instruction={product.heroBadges.instructions}
        badge={product.heroBadges.values[0]}
        offerPrice={product.offerPriceFormatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        handleCloseAllModal={this.handleCloseAllModal}
        key={`badge-${product.code}`}
        clickBadgeItem={clickBadgeItem}
      />);
    }

    const heroBadgeElements = heroBadges.length > 0 && heroBadges.map((badge, index) => <HeroBadge
      badge={badge}
      completeHeroBadges={product.completeHeroBadges}
      handleOpenCloseModal={this.handleOpenCloseModal}
      active={this.state.activeModal}
      offerPrice={product.offerPriceFormatted}
      key={`hero-badge-element-${index}`}
      id={`hero-badge-element-${index}`}
      idNumber={index}
      productName={product.name}
    />);
    const promoBadgeElements = promoBadges.length > 0 && promoBadges.map((badge, index) => <HeroBadge
      badge={badge}
      completeHeroBadges={product.completeHeroBadges}
      handleOpenCloseModal={this.handleOpenCloseModal}
      active={this.state.activeModal}
      offerPrice={product.offerPriceFormatted}
      key={`hero-badge-element-${index}`}
      id={`hero-badge-element-${index}`}
      idNumber={index}
      productName={product.name}
    />);

    const appendUrl = appendQueryToUrl || '';

    if (this.props.badgeInformation !== undefined && this.props.badgeInformation !== false) {
      badgeNameFinder = this.props.badgeInformation.find(badge => badge.id === `${product.name}-0`);
    }

    let gridItemClass
    if (badgeNameFinder !== undefined && badgeNameFinder !== false) {
      gridItemClass = badgeNameFinder.badgeState ? "grid__list-item text-center item-border glowing-border" : "grid__list-item text-center item-border";
    } else {
      gridItemClass = "grid__list-item text-center item-border"
    }
    
    productDataAndPrices = (<ProductPricesComponent product={product} carousel={this.props.carousel} />);

    return (
      <div className={gridItemClass}>
        <div className="product-meta__tile" title={product.name}>{product.name}</div>
        {
          heroBadgeElements && (
            <div className="product-badge--top-left">
              {heroBadgeElements}
            </div>
          )
        }

        {
          product.shoppingAvailable &&
          <div className="product-badge--top-left">
            {heroBadge}
          </div>
        }


        {
          product.categoryIcons && product.categoryIcons[0] != null &&
            <div className="product-icon--top-left">
              <img src={product.categoryIcons[0].url} className="category-product-icon-image"/>
            </div>

        }
        
        <div className={`product-list__image-wrapper ${!product.shoppingAvailable ? 'notAvailable' : ''} ${enableInfiniteScroll ? 'product-list__image-wrapper--lazy-img' : ''}`}>

          <a className="product-link" href={product.url ? `${product.url}${appendUrl}` : ''} onClick={this.handleProductClick}>
            <Img
              cName={`product-image ${cName || ''}`}
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
              onFocus={this.handleMouseOver}
              onBlur={this.handleMouseOut}
              media={{
                url: imgSrc,
                infiniteUrl: imgSrcInfinite
              }}
              sizes={{
                s: hasMoreProductsPerRow ? '25vw' : '30vw',
                m: hasMoreProductsPerRow ? '30vw' : '50vw',
                l: hasMoreProductsPerRow ? '20vw' : '30vw',
                xl: hasMoreProductsPerRow ? '20vw' : '30vw',
                xxl: hasMoreProductsPerRow ? '20vw' : '30vw',
              }}
              alt={product.imageAlt || product.name}
              updateImageReferences={this.updateImageReferences}
              lazyLoad={enableInfiniteScroll}
            />
          </a>
        </div>
        {
          !product.shoppingAvailable &&
          <a className="product-link" href={product.url ? `${product.url}${appendUrl}` : ''} onClick={this.handleProductClick}>
            <div className="product-not-available">
              <FormattedMessage id="products.pdp.notAvailable" />
            </div>
          </a>
        }


        {productDataAndPrices}

        {
          promoBadgeElements && (
            <div className="product-badge--bottom">
              {promoBadgeElements}
            </div>
          )
        }

        {
          enableAddToBag && product.shoppingAvailable && (
            <AddToBag sku={`V${product.id}`} />
          )
        }
      </div>
    );
  }
}

ProductItem.propTypes = {
  product: PropTypes.object.isRequired,
  hasMoreProductsPerRow: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  resultPosition: PropTypes.number.isRequired,
  productItemMounted: PropTypes.func,
  cName: PropTypes.string,
  updateImageReferences: PropTypes.func,
  enableInfiniteScroll: PropTypes.bool,
  appendQueryToUrl: PropTypes.string,
  setClickBadgeItem: PropTypes.func
};


const mapStateToProps = state => {
  let badgeInformation;
  let clickBadgeItem;
  if (state.clickBadgeItem){
    clickBadgeItem = state.clickBadgeItem;
  }
  if (state.badgeStateControl) {
    badgeInformation = state.badgeStateControl.badges;
  } else {
    badgeInformation = false;
  }
  return ({ badgeInformation, clickBadgeItem }
  );
};


export default connect(mapStateToProps)(ProductItem);
