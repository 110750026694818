export const LOGIN_URL = '/login';

export const REGISTRATION_URL = '/register';
export const GUEST_URL = '/guest';
export const GUEST_ADDRESS_URL = '/guest/address';

export const FORGOT_PASSWORD_URL = '/forgotPassword';
export const FORGOT_PASSWORD_CONFIRMATION_URL = '/forgotPassword/confirmation';

export const RESET_PASSWORD_URL = '/forgotPassword/reset';

export const SET_MIGRATION_MSG = 'SET_MIGRATION_MSG';

export const AUTH_APP_PAGE_MOUNT = 'AUTH_APP_PAGE_MOUNT';
export const LOGIN_FORM_CLICK = 'LOGIN_FORM_CLICK';
export const REGISTRATION_FORM_CLICK = 'REGISTRATION_FORM_CLICK';
export const GUEST_FORM_CLICK = 'GUEST_FORM_CLICK';
export const GUEST_FORM_ADDRESS_CLICK = 'GUEST_FORM_ADDRESS_CLICK';
export const FORGOT_PASSWORD_CLICK = 'FORGOT_PASSWORD_CLICK';
export const PASSWORD_CONFIRMATION_MOUNT = 'PASSWORD_CONFIRMATION_MOUNT';

export const ENABLE_SUBMIT_BUTTON = 'ENABLE_SUBMIT_BUTTON';
export const DISABLED_SUBMIT_BUTTON = 'DISABLED_SUBMIT_BUTTON';

export const MEMBERID_LOOKUP_LOAD_STATE_URL = '/member/getMemberLookup';
export const MEMBERID_LOOKUP_POST_CODE_STATE_URL = '/member/getMemberLookupByPostCode';
export const MEMBERID_UPDATE_LOAD_STATE_URL = '/member/updateMemberLookup';

export const MEMBERID_CHANGED = 'MEMBERID_CHANGED';
export const MEMBERID_LOOKUP = 'MEMBERID_LOOKUP';
export const MEMBERID_LOOKUP_RESULT = 'MEMBERID_LOOKUP_RESULT';
export const MEMBERID_LOOKUP_CHANGED = 'MEMBERID_LOOKUP_CHANGED';
export const MEMBERID_LOOKUP_PREPOPULATED = 'MEMBERID_LOOKUP_PREPOPULATED';
export const MEMBERID_LOOKUP_URL = '/member/lookup';

export const MEMBERID_LOOKUP_LOAD_STATE = 'MEMBERID_LOOKUP_LOAD_STATE';
export const MEMBERID_LOOKUP_LOAD_STATE_RESULT = 'MEMBERID_LOOKUP_LOAD_STATE_RESULT';
export const MEMBERID_OPTIONBOX_CHANGE = 'MEMBERID_OPTIONBOX_CHANGE';
export const MEMBERID_LOOKUP_ERROR_LOAD_STATE_RESULT = 'MEMBERID_LOOKUP_ERROR_LOAD_STATE_RESULT';
export const MEMBER_UPDATE_STATE = 'MEMBER_UPDATE_STATE';

export const MEMBERID_LOOKUP_UPDATE_STATE_RESULT = 'MEMBERID_LOOKUP_UPDATE_STATE_RESULT';
export const MEMBERID_LOOKUP_ERROR_UPDATE_STATE_RESULT = 'MEMBERID_LOOKUP_ERROR_UPDATE_STATE_RESULT';

export const MEMBERID_LOOKUP_GET_STATE_RESULT = 'MEMBERID_LOOKUP_GET_STATE_RESULT';
export const MEMBERID_LOOKUP_ERROR_GET_STATE_RESULT = 'MEMBERID_LOOKUP_ERROR_GET_STATE_RESULT';
