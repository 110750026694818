import React from 'react';
import PropTypes from 'prop-types';

const productMetaWasPrice = ({price, offerPrice, cName, id_badge}) => (
  <div className={cName}>{price} o ll&eacute;valo por <span className="product-list-component__special-price">${offerPrice}</span>{(id_badge  == '2_o_mas' || id_badge  == 'oferta_2x_mismo_producto') ? <span style={{ fontSize: '12px', color: '#f96f00' }}> c/u </span> : ''}</div>
);

productMetaWasPrice .propTypes = {
  price: PropTypes.any,
  offerPrice: PropTypes.any,
  cName: PropTypes.string
 };

export default productMetaWasPrice;