import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class ModalWrapper extends Component {
  constructor() {
    super();
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
  }

  getContent() {
    const { children, isHTMLContent } = this.props;

    if (isHTMLContent) {
      return (<div dangerouslySetInnerHTML={{ __html: children }} />);
    }
    return children;
  }

  getTitle() {
    const { titleMsg, titleMsgID, titleMsgHTML } = this.props;

    let result;
    if (titleMsg) {
      result = <h2>{titleMsg}</h2>;
    } else if (titleMsgID) {
      result = <FormattedMessage id={titleMsgID} />;
    } else if (titleMsgHTML) {
      result = <h2 dangerouslySetInnerHTML={{ __html: titleMsgHTML }} />;
    }

    return result ? (
      <div className="modal__window-heading">
        {result}
      </div>
    ) : null;
  }

  handleBackgroundClick(e) {
    const { modalToggle } = this.props;

    if (e.target === e.currentTarget) {
      modalToggle();
    }
  }

  render() {
    const {
      maxWidth, customParentClass, isModalOpen, modalToggle, hasSmallPadding = false
    } = this.props;

    if (isModalOpen === false) {
      return null;
    }

    return (
      <div className="modal__backdrop" onClick={this.handleBackgroundClick}>
        <div className={`modal__window modal__window-size--${maxWidth} ${customParentClass} modal__small-padding--${hasSmallPadding}`}>
          {this.getTitle()}
          <button className="modal__window-close icon-close" onClick={modalToggle} />
          <div className="modal__window-container">
            {this.getContent()}
          </div>
        </div>
      </div>
    );
  }
}

ModalWrapper.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  modalToggle: PropTypes.func.isRequired,
  customParentClass: PropTypes.string,
  maxWidth: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  isHTMLContent: PropTypes.bool,
  titleMsg: PropTypes.string,
  titleMsgID: PropTypes.string,
  titleMsgHTML: PropTypes.string,
  hasSmallPadding: PropTypes.bool
};

ModalWrapper.defaultProps = {
  maxWidth: 500,
  customParentClass: ''
};

export default ModalWrapper;
