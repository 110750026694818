import ThumborUrlBuilder from 'thumbor-url-builder';

export default class ThumborUtils extends ThumborUrlBuilder {
  constructor(config) {
    const key = config.unsafeMode === 'true' ? null : config.key;
    const baseUrl = config.baseUrl.replace(/\/$/, '');

    // For some reason this super call is not running the parent constructor
    super(key, baseUrl);
    // so I have to manually initialize some variables:

    this.THUMBOR_SECURITY_KEY = key;
    this.THUMBOR_URL_SERVER = baseUrl;
    this.imagePath = '';
    this.width = 0;
    this.height = 0;
    this.smart = false;
    this.fitInFlag = false;
    this.withFlipHorizontally = false;
    this.withFlipVertically = false;
    this.halignValue = null;
    this.valignValue = null;
    this.cropValues = null;
    this.meta = false;
    this.filtersCalls = [];

    this.config = config;
    this.trimFlag = false;
    this.aspectRatio = this.config.aspectRatio !== '' && this.config.aspectRatio !== null ?
      this.config.aspectRatio.split(':').map((val) => parseInt(val, 10)) : null;
  }

  urlParts() {
    const urlPartsArray = super.urlParts();

    if (this.trimFlag) {
      return ['trim', ...urlPartsArray];
    }

    return urlPartsArray;
  }

  getHeightFromAspectRatio(width) {
    const [divisor, dividend] = this.aspectRatio;
    return Math.round((width * dividend) / divisor);
  }

  trim() {
    this.trimFlag = true;
    return this;
  }

  fitIn(width, height) {
    let imgHeight = height;

    if (this.aspectRatio) {
      imgHeight = this.getHeightFromAspectRatio(width);
    }

    return super.fitIn(width, imgHeight);
  }
}
