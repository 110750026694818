import * as CONST from './globalMessagesConstants';

const initialState = {};

const GlobalMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SET_SERVER_MSG:
      return {
        ...state,
        message: action.message,
        msgType: action.msgType,
        shouldAutoHide: action.shouldAutoHide,
        isLocalisableMessageId: action.isLocalisableMessageId,
        isStickyMessage: action.isStickyMessage,
        values: action.values,
        shouldBeClosed: false
      };
    case CONST.CLOSE_MESSAGE:
      return {
        ...state,
        shouldBeClosed: true
      };
    default:
      return state;
  }
};

export default GlobalMessagesReducer;
