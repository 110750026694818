import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BACK_TO_TOP_DURATION } from './../productList/productListConstants';
import { scrollToPosition } from 'app/utils/helpers';

export default class BackToTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: 0,
      previousScrollTop: 0
    };

    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollToTop(event) {
    const { duration } = this.props;
    event.preventDefault();
    scrollToPosition(0, duration);
  }

  handleScroll() {
    const previousScrollTop = this.state.scrollTop;

    this.setState({
      previousScrollTop,
      scrollTop: window.pageYOffset
    });
  }

  render() {
    const isScrollDown = (this.state.scrollTop > this.state.previousScrollTop);
    const touchDeviceClass = (Modernizr.touch) ? 'touch' : '';
    const showBackToTopBtn = (this.state.scrollTop >= this.props.offset);
    const backToTopDefaultClass = `back-to-top-btn ${touchDeviceClass}`;

    let resultClass;

    if (!showBackToTopBtn) {
      resultClass = backToTopDefaultClass;
    } else if (isScrollDown) {
      resultClass = `${backToTopDefaultClass} scroll-down`;
    } else {
      resultClass = `${backToTopDefaultClass} scroll-top`;
    }

    return (
      <div className={resultClass} onClick={this.scrollToTop}>
        <span className="icon-back-to-top" />
      </div>
    );
  }
}

BackToTop.propTypes = {
  duration: PropTypes.number,
  offset: PropTypes.number
};

BackToTop.defaultProps = {
  duration: BACK_TO_TOP_DURATION,
  offset: 100
};
