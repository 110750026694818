import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { priceRegexTransformer } from 'app/utils/priceRegexTransformer';
import ProductMetaPrice from './productMetaPrice';
import ProductMetaWasPrice from './productMetaWasPrice';

const ProductPricesComponent = ({ product, carousel }) => {

  let textInContainer;
  const parseNum = 2;
  const textTransformer = (arr) => (<span>{arr[0]}.<span style={{ fontSize: "10px" }}>{arr[1]}</span></span>)

  let price;
  if (product.priceFormatted) {
    price = product.priceFormatted;
  } else if (product.price.value) {
    price = `$${parseFloat(product.price.value).toFixed(parseNum)}`;
  } else {
    price = 0;
  }
  const parcedPrice = parseFloat(price.slice(1)).toFixed(parseNum);
  const offerPrice = (product.offerPrice) ? parseFloat(product.offerPrice).toFixed(parseNum) : 0;
  const wasPrice = (product.wasPrice) ? parseFloat(product.wasPrice.value).toFixed(parseNum) : 0;
  const productId = (product.id) ? product.id : null;

  if(product.shoppingAvailable){
    if (carousel) {
      if (wasPrice > 0) {
        const wasPriceArr = wasPrice.toString().split('.');
        textInContainer = <ProductMetaPrice price={textTransformer(wasPriceArr)} />
      } else {
        const priceFormattedArr = price.toString().split('.');
        textInContainer = <ProductMetaPrice price={textTransformer(priceFormattedArr)} />
      }
    } else {
      if (wasPrice > 0) {
        const wasPriceFormattedArr = priceRegexTransformer(wasPrice);
        const fixedOferPriceFormattedArr = priceRegexTransformer(offerPrice);
        if (parcedPrice === offerPrice && parcedPrice < wasPrice) {
          textInContainer = (
            <div>
              <p>
                <span className="product-meta__block-price">${textTransformer(wasPriceFormattedArr)} </span>&nbsp;&nbsp;&nbsp;<span className="product-list-component__special-price">${textTransformer(fixedOferPriceFormattedArr)}</span>
              </p>
            </div>);
        } else {
          textInContainer = <ProductMetaWasPrice cName="product-meta__was-price" price={textTransformer(wasPriceFormattedArr)} offerPrice={textTransformer(fixedOferPriceFormattedArr)} />
        }
      } else if (offerPrice > 0) {
        const fixedPriceFormatted = priceRegexTransformer(price.toString());
        const fixedOfferPriceArr = priceRegexTransformer(offerPrice.toString());
        textInContainer = <ProductMetaWasPrice cName="product-meta__was-price" price={textTransformer(fixedPriceFormatted)} offerPrice={textTransformer(fixedOfferPriceArr)} id_badge={product.completeHeroBadges.id_badge} />
      } else {
        const fixedOfferPriceArr = price.toString().split('.');
        textInContainer = <ProductMetaPrice price={textTransformer(fixedOfferPriceArr)} />
      }
    }
  }

  return (
    <div className={`product-meta__container ${!product.shoppingAvailable ? 'notAvailable' : ''}`}>
      {productId &&
        <div className="product-meta__wrapper">
          <div className="product-meta__product-code">
            <FormattedMessage id="articles.articgrid.code" description="Codigo" /> {productId}
          </div>
        </div>
      }
      {textInContainer}
    </div>
  );
}

ProductPricesComponent.propTypes = {
  product: PropTypes.object.isRequired,
  carousel: PropTypes.bool
};

export default ProductPricesComponent;
