import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Checkbox = ({
  checkboxName,
  checkboxInputClass,
  checkboxLabelId,
  checkboxChecked,
  checkboxOnChange
}) => (
  <label htmlFor={checkboxName}>
    <span className="form-checkbox">
      <input
        type="checkbox"
        name={checkboxName}
        id={checkboxName}
        value={checkboxName}
        className={`form-checkbox__input ${checkboxInputClass || ''}`}
        checked={checkboxChecked}
        onChange={checkboxOnChange}
      />
      <span className="form-checkbox__checkbox" />
    </span>
    <span className="form-checkbox__label">
      <FormattedMessage id={checkboxLabelId} />
    </span>
  </label>
);

Checkbox.propTypes = {
  checkboxName: PropTypes.string.isRequired,
  checkboxLabelId: PropTypes.string.isRequired,
  checkboxInputClass: PropTypes.string,
  checkboxChecked: PropTypes.bool,
  checkboxOnChange: PropTypes.func
};

export default Checkbox;
