import { PRDUCT_ITEM_MOUNT } from '../products/components/productList/productListConstants';
import { NO_RESULTS } from '../products/components/noResults/noResultsConstants';
import { RECEIVE_CART_ITEMS_SUCCESSFULLY } from '../orderPlacement/cart/components/cartList/cartListConstants';
import { EMPTY_CART } from '../orderPlacement/cart/components/emptyCart/emptyCartConstants';
import { ACCOUNT_INFO_MOUNT, ORDER_HISTORY_MOUNT, ADDRESS_BOOK_MOUNT, PAYMENT_INFO_MOUNT } from '../account/accountConstants';
import { RECEIVE_ORDER_DETAILS } from '../account/components/orderHistory/orderDetails/orderDetailsConstants';
import { AUTH_APP_PAGE_MOUNT } from '../auth/authFormConstants';
import { CSA_LOGIN_APP_PAGE_MOUNT } from '../auth/csalogin/csaLoginConstants';
import { RESET_PASSWORD_PAGE_MOUNT } from '../auth/layouts/resetPassword/resetPasswordConstants';
import { REMOVE_FOOTER_INVISIBILITY } from '../cmscomponents/footer/footerConstants';
import { saveStorageData } from '../localStorage/localStorage';

const { pageType } = window.inlineGlobalConfig;

/*
  This is a custom pub sub written which allows listening to Redux actions outside of the app ecosystem
  This is useful to provide customer specific analytics listeners without polluting the code.
  Please discuss with your peers before using this for non analytics purposes
  Usage:
  $(document).on('ACTION_TYPE', callbackFn)
  callbackFn(event, actionData, store)
*/

const displayFooter = (() => {
  let footerHidden = true;

  return () => {
    if (footerHidden) {
      const footer = document.querySelector('.footer');

      if (footer) {
        footer.classList.remove('invisible');
      } else {
        saveStorageData(REMOVE_FOOTER_INVISIBILITY, 'true');
      }

      if (pageType === 'bag') {
        document.querySelector('.cart__media-banner').classList.remove('invisible');
      }

      footerHidden = false;
    }
  };
})();

export default function customEvents(store) {
  const delayFooterLoadOnPages = ['product-list', 'search-results', 'bag', 'my-account', 'identity'];
  const delayFooterThroughLastActions = [PRDUCT_ITEM_MOUNT, NO_RESULTS, RECEIVE_CART_ITEMS_SUCCESSFULLY, EMPTY_CART,
    ACCOUNT_INFO_MOUNT, ORDER_HISTORY_MOUNT, RECEIVE_ORDER_DETAILS, ADDRESS_BOOK_MOUNT, PAYMENT_INFO_MOUNT,
    AUTH_APP_PAGE_MOUNT, CSA_LOGIN_APP_PAGE_MOUNT, RESET_PASSWORD_PAGE_MOUNT
  ];

  // TODO: Find alternative way to check if events has attached to document
  // ----------------------------------------------------------------------
  // const hasAnEventListenerToThisAction =
  // $._data(document, 'events')
  // && $._data(document, 'events')[reduxAction.type];

  // if (hasAnEventListenerToThisAction) {
  //   $(document).trigger(reduxAction.type, [reduxAction, store]);
  // }

  return next => reduxAction => {
    const event = new CustomEvent(reduxAction.type, {
      detail: {
        reduxAction,
        store
      }
    });
    document.dispatchEvent(event);
    if (delayFooterLoadOnPages.includes(pageType) && delayFooterThroughLastActions.includes(reduxAction.type)) {
      displayFooter();
    }

    return next(reduxAction);
  };
}
