export const TYPE_ERROR = 'alert';
export const TYPE_SUCCESS = 'success';
export const TYPE_WARNING = 'warning';

export const GLOBAL_MESSAGES_ELEMENT_ID = 'GlobalMessages';

export const SUPPORTED_GLOBAL_MESSAGES = [TYPE_ERROR, TYPE_SUCCESS, TYPE_WARNING];

export const MAX_DISPLAY_TIME = 50000;

export const SET_SERVER_MSG = 'SET_SERVER_MSG';

export const DEFAULT_ERROR_MSG = 'Se ha producido un error. Por favor inténtelo de nuevo.';

export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const CART_MESSAGES_STORAGE = 'CART_MESSAGES_STORAGE';

export const SESSION_STORAGE = 'sessionStorage';

export const CLOSE_MESSAGE = 'CLOSE_MESSAGE';
