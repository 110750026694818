export const SELECT_FILTER = 'SELECT_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SELECT_FILTER_TRIGGER = 'SELECT_FILTER_TRIGGER';
export const CLEAR_FILTER_TRIGGER = 'CLEAR_FILTER_TRIGGER';

// used long selectors, because we dispatch actions at the end of animation, so we need only 1 element to animate and to avoid dispatching duplicate action
export const APPLIED_FILTER_SELECTOR_SMALL = '.show-for-small-up .applied-filters-wrapper';
export const APPLIED_FILTER_SELECTOR_MEDIUM_UP = '.show-for-large-up .applied-filters-container';

export const COLOR_GROUP_ID = '@colorGroup';
export const TECHNOLOGY_GROUP_ID = '@technology';
export const SPAN_BEHAVIOR_COLORS = ['black-white', 'white'];

export const SET_ANIMATE_CONTAINER_CLASS = 'SET_ANIMATE_CONTAINER_CLASS';

export const ANIMATION_CLASSES = {
  HEIGHT_INCREASE: 'height-increase-animation',
  HEIGHT_DECREASE: 'height-decrease-animation',
  OPACITY_DECREASE: 'opacity-decrease-animation',
  OPACITY_INCREASE: 'opacity-increase-animation',
  MAX_HEIGHT: 'max-height'
};
